.usersession {
  width: 250%;
  height: auto;
  border-radius: 5px;
  border: none;
  margin-left: 5px;
  position: relative;
}
.usersession-heading-text {
  font-size: 18px;
  font-weight: bold;
  float: left;
  display: table-column;
  padding: 10px;
  width: 30%;
  background-color: #99d4cf;
  margin-top: 0% !important;
  margin-bottom: 0%;
}
.usersession .body-text {
  font-size: 14px;
  color: #494b52;
  float: left;
  display: table-column;
  padding: 5px;
  width: 30%;
  height: 31px;
  margin-top: 0%;
  padding-top: 0%;
}

.usersession .column-width {
  width: 40% !important;
  margin-top: 0% !important;
}

@media screen and (max-width: 768px) {
  .usersession {
    width: 150%;
    margin-left: 5px !important;
  }
  .usersession-heading-text {
    width: 30%;
    font-size: 15px;
    margin-bottom: 20%;
  }
  .usersession .body-text {
    width: 100%;
  }
  .usersession .column-width {
    width: 250%;
    font-weight: bolder;
  }
}

@media screen and (max-width: 420px) {
  .usersession {
    width: 100%;
    margin-left: 0% !important;
  }
  .min-text {
    width: 250% !important;
    font-size: 13px;
    margin: auto;
    /* justify-content: stretch; */
    padding-bottom: auto;
    padding-right: 5%;
  }
  .start-stop-text {
    display: none;
  }
  .usersession .body-text {
    width: 100%;
  }
  .usersession .column-width {
    width: 250% !important;
    margin-top: 0%;
    font-weight: bolder;
    margin-left: 0%;
  }
}
