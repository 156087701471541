.navbar-large {
  height: 125px;
  width: 98vw;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  padding-left: 2vw;
  margin: 0;
  box-sizing: border-box;
  display: flex;
  z-index: 998 !important;
  background: transparent !important;
}

.navbar-large a,
.navbar-large a:hover {
  text-decoration: none;
  color: #000;
}

/* Left container */
.navbar-large .left-container {
  flex: 1;
  height: 100%;
  padding: 1.5% 0 0 2%;
}

.navbar-large .link-homepage {
  width: 10vw;
  height: 5vw;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

/* Middle container */
.navbar-large .middle-container {
  flex: 4;
  height: 100%;
  max-width: 800px;
}

.navbar-large .links-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background-repeat: no-repeat;
  background-position-y: 42px;
  background-position-x: 50px;
}

.navbar-large .link-blah {
  width: 115px !important;
  height: 85px !important;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-large .link-content-container {
  width: 105px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 12px !important;
  border-radius: 3px;
  font-weight: 600;
  color: var(--main-color-black);
  box-shadow: 1px 1px 7px 0 var(--boxshadow) !important;
  margin: auto;
}

.navbar-large .link-resources .link-content-container {
  background-image: url('../../../assets/navbarLarge/cards/resources-card.svg');
}

.navbar-large .link-resources .link-content-container .link-title {
  color: var(--text-resources);
}

.navbar-large .link-network .link-content-container {
  background-image: url('../../../assets/navbarLarge/cards/network-card.svg');
}

.navbar-large .link-network .link-content-container .link-title {
  color: var(--text-network);
}

.navbar-large .link-wellbeing .link-content-container {
  background-image: url('../../../assets/navbarLarge/cards/wellbeing-card.svg');
}

.navbar-large .link-wellbeing .link-content-container .link-title {
  color: var(--text-wellbeing);
}

.navbar-large .link-strategies .link-content-container {
  background-image: url('../../../assets/navbarLarge/cards/strategies-card.svg');
}

.navbar-large .link-strategies .link-content-container .link-title {
  color: var(--text-strategies);
}

.navbar-large .link-planning .link-content-container {
  background-image: url('../../../assets/navbarLarge/cards/planning-card.svg');
}

.navbar-large .link-planning .link-content-container .link-title {
  color: var(--text-planning);
}

.navbar-large .link-mworks .link-content-container {
  background-image: url('../../../assets/navbarLarge/cards/mworks-card.svg');
}

.navbar-large .link-mworks .link-content-container .link-title {
  color: var(--text-mworks);
}

.navbar-large .standalone-link-title {
  position: absolute;
  top: 40px;
  left: 30px;
}

.navbar-large .link-content-container .logo {
  width: 50px;
  height: 35px;
  margin: 0px 27px 13px 27px;
}

.navbar-large .standalone-logo {
  padding-top: 7px;
}

.active-nav-link::after {
  background-color: black;
  bottom: 10px;
  content: '';
  display: block;
  height: 4px;
  position: absolute;
  width: 30px;
}

.active-mworks::after {
  background-color: var(--background-color-mworks);
}

.active-myresources::after {
  background-color: var(--background-color-resources);
}

.active-mynetwork::after {
  background-color: var(--background-color-network);
}

.active-wellbeing::after {
  background-color: var(--background-color-wellbeing);
}

.active-strategies::after {
  background-color: var(--background-color-strategies);
}

.active-planning::after {
  background-color: var(--background-color-planning);
}

/* Right container */
.navbar-large .right-container {
  flex: 1;
  height: 100%;
  padding: 1.5% 2% 0 0;
}

.navbar-large .right-container .logout-link {
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  color: #000;
  text-align: right;
  cursor: pointer;
  margin: 0;
}

@media (max-width: 1100px) {
  .navbar-large .link {
    width: 95px;
    height: 75px;
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 12px !important;
    border-radius: 3px;
    font-weight: 600;
    color: var(--main-color-black);
  }
}
