.homepage {
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homepage .cloud-info {
  position: absolute;
  top: 63px;
  padding-left: 70px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.homepage .cloud-title {
  width: 245px;
  height: 32px;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #24655f;
  margin-left: 10px;
  margin-bottom: 10px;
}
.homepage .cloud-text {
  width: 229px;
  height: 44px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #24655f;
  margin-left: 19px;
}

/* NAVBAR */
.homepage .navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.homepage .logout-link {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.homepage .mworks_logo {
  width: 35px;
  height: 36px;
  object-fit: contain;
  margin: 0px 15px 25px 0px;
}
.homepage .background-container {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

.homepage .background-container img {
  width: 100%;
}

.homepage .main-container {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
}

/* TOP SECTION */
.homepage .top-section {
  position: relative;
  top: 5px;
  width: 100%;
  height: 350px;
  display: flex;
  justify-content: space-between;
}

.homepage .top-section .left-section {
  padding-top: 0.3%;
  width: 30%;
}

.homepage .top-section .left-section .header {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-mworks);
}

.homepage .top-section .left-section .content {
  font-size: 16px;
  font-weight: 600;
  color: var(--main-color-black);
}

.homepage .top-section .left-section .read-more {
  background-color: var(--main-color-black);
  color: #fff;
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  padding: 1% 6%;
  font-weight: bold;
  outline: none;
}

.homepage .top-section .left-section .about-mworks {
  background-color: transparent;
  padding: 1% 6%;
  font-weight: bold;
  outline: none;
  border: none;
}

/* Modal */
.homepage .logo-small__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 10%;
  position: relative;
}

.modal-body {
  height: 500px;
}

.homepage .logo-small__container .waves-container {
  height: 75%;
  width: 67%;
  position: absolute;
  z-index: 1;
  left: 17.5%;
  top: 0;
  background: url('../../assets/homepage/waves_new.png');
  background-size: contain;
  background-repeat: no-repeat;
}

.homepage .logo-small__top-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3% 0;
  z-index: 2;
}

.homepage .logo-small__bottom-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 3% 0;
  z-index: 2;
}

.homepage .logo-small__bottom-row .logo-small__logo {
  height: 75%;
}

.homepage .logo-small {
  height: 65px;
  width: 65px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage .logo-small__logo {
  height: 50%;
}

.homepage .top-section .center-section {
  height: 100%;
  display: flex;
  justify-content: center;
}

.homepage .top-section .center-section .logo {
  width: 400px;
}

.homepage .top-section .center-section .mymworks-sphere {
  height: 400px !important;
  width: 400px !important;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 16px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 10%;
  text-decoration: none;
}

.homepage .mymworks-sphere .header {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  color: var(--main-color-black);
}

.homepage .mymworks-sphere .logo {
  height: 40%;
}

.homepage .mymworks-sphere .info-button {
  width: 150px;
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color-black);
  border: 1px solid var(--main-color-black);
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  padding: 1% 6%;
  outline: none;
  background-color: transparent;
  margin: 30px;
}

.homepage .top-section .center-section .content {
  width: 100%;
  font-weight: 600;
  text-align: center;
  color: var(--main-color-black);
  margin: auto;
}

.homepage .top-section .right-section {
  padding-top: 0.3%;
  width: 30%;
}

.text-color-mworks {
  color: var(--text-color-mworks);
  font-weight: bold;
}

/* todo: FIX POSITIONING OF THE WRAPPER!!! */
.homepage .middle-section .wrapper {
  background: url('../../assets/homepage/waves_new.png');
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  width: 85%;
  height: 175%;
  top: 0;
  left: 7.5%;
  position: absolute;
  z-index: 1;
}

/* MIDDLE SECTION */
.homepage .middle-section {
  height: 19rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.homepage .middle-section .content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding-top: 40px;
  background-repeat: no-repeat;
  background-position-y: 12px;
  background-position-x: 78px;
}

.homepage .content-container .waves-container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.homepage .content-container .waves-container .waves-image {
  position: relative;
  width: 87%;
  height: 147%;
  left: 7.5%;
  top: 0.5%;
  z-index: -10;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.homepage .middle-section .card__box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.homepage .middle-section .card__circle {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 28px;
  font-weight: bold;
  color: var(--main-color-black);
  z-index: 10;
}

.homepage .middle-section .card__box {
  height: 100%;
  width: 260px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
}

.homepage .middle-section .card__title {
  font-size: 24px;
  font-weight: 600;
}

.homepage .middle-section .card__logo-container {
  height: 30%;
  margin-bottom: 10%;
}

.homepage .middle-section .card__logo {
  height: 100%;
}

.homepage .middle-section .card__content {
  height: 28%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage .middle-section .card__info-text {
  font-size: 14px;
  font-weight: bold;
  color: var(--main-color-black);
  padding: 15%;
  text-align: center;
}

.homepage .middle-section .card__info-button {
  font-size: 16px;
  font-weight: bold;
  border-radius: 21.5px;
  padding: 1% 6%;
  outline: none;
  background-color: transparent;
  width: 125px;
  height: 40px;
}

/* BOTTOM SECTION */
.homepage .bottom-section {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 332px;
  display: flex;
  justify-content: center;
}

.homepage .bottom-section__box-container {
  height: 100%;
  width: 19%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
}

.homepage .bottom-section__circle {
  height: 70px;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 40px;
  font-weight: bold;
  color: #fff;
  background-color: #dfd7ea;
  position: absolute;
  top: -22%;
  z-index: 15;
}

.homepage .bottom-section__box {
  height: 75%;
  max-height: 175px;
  background-color: #c3b2dc;
  width: 90%;
  box-shadow: 2px 6px 16px 0 rgba(41, 28, 28, 0.15);
  border-radius: 5px;
  position: absolute;
  bottom: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 15% 2% 7% 2%;
  text-align: center;
  box-sizing: border-box;
  z-index: 3 !important;
}

.homepage .bottom-section__title {
  font-size: 20px;
  font-weight: 600;
  color: var(--main-color-black);
  /* padding: 15% 2% 2% 2%; */
}

.homepage .bottom-section__info-text {
  font-size: 0.9vw;
  font-weight: bold;
  padding: 3%;
  text-align: center;
  box-sizing: border-box;
  color: var(--main-color-black);
}

.homepage .bg-mworks {
  background-color: var(--background-color-mworks);
}

.cards {
  display: flex;
  justify-content: center;
  z-index: 2;
  margin-right: 20px;
  height: 255px;
  width: 260px;
}

.bg-resources {
  background-image: url('../../assets/myResources/resurser.svg');
  background-repeat: no-repeat;
}
.bg-resources .card__box {
  color: #674900;
}

.bg-resources .card__info-button {
  color: #674900;
  border: 1px solid #674900;
}

.bg-network {
  background-image: url('../../assets/myNetwork/n-tverk.svg');
  background-repeat: no-repeat;
}

.bg-network .card__box {
  color: #7d3d00;
}

.bg-network .card__info-button {
  color: #7d3d00;
  border: 1px solid #7d3d00;
}

.bg-wellbeing {
  background-image: url('../../assets/myWellbeing/m-ende.svg');
  background-repeat: no-repeat;
}

.bg-wellbeing .card__box {
  color: #32547d;
}

.bg-wellbeing .card__info-button {
  color: #32547d;
  border: 1px solid #32547d;
}

.bg-strategies {
  background-image: url('../../assets/myStrategies/strategier.svg');
  background-repeat: no-repeat;
}

.bg-strategies .card__box {
  color: #5b1420;
}

.bg-strategies .card__info-button {
  color: #5b1420;
  border: 1px solid #5b1420;
}

.bg-planning {
  background-image: url('../../assets/myPlanning/planering.svg');
  background-repeat: no-repeat;
}

.bg-planning .card__box {
  color: #364c1c;
}

.bg-planning .card__info-button {
  color: #364c1c;
  border: 1px solid #364c1c;
}

.homepage .bg-back-to-work {
  background-color: #c3b2dc;
  position: absolute;
  top: 0;
}

.homepage .circle-bg-resources {
  background-color: #ffecb4;
  position: absolute;
  top: 0;
}

.homepage .circle-bg-network {
  background-color: #ffd496;
  position: absolute;
  top: 0;
}

.homepage .circle-bg-wellbeing {
  background-color: #d2e5fc;
  position: absolute;
  top: 0;
}

.homepage .circle-bg-strategies {
  background-color: #f4bbc5;
  position: absolute;
  top: 0;
}

.homepage .circle-bg-planning {
  background-color: #d7eabf;
  position: absolute;
  top: 0;
}

@media (max-width: 1240px) {
  .homepage .middle-section {
    margin-left: 5px !important;
  }
  .homepage .middle-section .card__circle {
    height: 58px !important;
    width: 58px !important;
    font-size: 20px !important;
  }
  .homepage .middle-section .card__title {
    font-size: 20px !important;
  }
  .cards {
    margin-right: 15px !important;
    height: 225px !important;
    width: 230px !important;
    border-radius: 3% !important;
  }

  .homepage .mymworks-sphere .info-button {
    width: 100px;
    font-size: 14px;
    margin: 45px;
  }

  .homepage .bottom-section__title {
    padding: 10% 0 5% 0;
    font-size: 18px;
  }
}
@media (max-width: 1200px) {
  .homepage .middle-section {
    margin-left: 5px !important;
  }

  .homepage .middle-section .card__circle {
    height: 58px !important;
    width: 58px !important;
    font-size: 20px !important;
  }
  .homepage .middle-section .card__title {
    font-size: 20px !important;
  }
  .cards {
    margin-right: 10px !important;
    height: 225px !important;
    width: 230px !important;
    border-radius: 3% !important;
  }

  .homepage .mymworks-sphere .info-button {
    width: 100px;
    font-size: 14px;
    margin: 45px;
  }

  .homepage .bottom-section__title {
    padding: 10% 0 5% 0;
    font-size: 18px;
  }
}

@media (max-width: 1100px) {
  .homepage .middle-section {
    margin-left: 5px !important;
  }
  .homepage .middle-section .card__circle {
    height: 58px !important;
    width: 58px !important;
    font-size: 20px !important;
  }
  .homepage .middle-section .card__title {
    font-size: 20px !important;
    padding-top: 10px !important;
  }
  .homepage .middle-section .card__content {
    height: 10% !important;
  }
  .cards {
    margin-right: 12px !important;
    height: 200px !important;
    width: 205px !important;
    border-radius: 3% !important;
  }
  .homepage .middle-section .card__info-text {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .homepage .middle-section {
    margin-left: 5px !important;
  }
  .homepage .middle-section .card__circle {
    height: 58px !important;
    width: 58px !important;
    font-size: 20px !important;
  }
  .homepage .middle-section .card__title {
    font-size: 20px !important;
    padding-top: 10px !important;
  }
  .homepage .middle-section .card__content {
    height: 10% !important;
  }
  .cards {
    margin-right: 10px !important;
    height: 190px !important;
    width: 195px !important;
    border-radius: 3% !important;
  }
  .homepage .middle-section .card__info-text {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .homepage .middle-section {
    margin-left: 5px !important;
  }
  .homepage .middle-section .card__circle {
    height: 58px !important;
    width: 58px !important;
    font-size: 20px !important;
  }
  .homepage .middle-section .card__title {
    font-size: 20px !important;
    padding-top: 10px !important;
  }
  .homepage .middle-section .card__content {
    height: 10% !important;
  }
  .cards {
    margin-right: 5px !important;
    height: 190px !important;
    width: 195px !important;
    border-radius: 3% !important;
  }
  .homepage .middle-section .card__info-text {
    font-size: 12px;
  }
}

/* @media (max-width: 1080px) {
  .homepage .top-section .center-section .content {
    // padding: 3%;
    // width: 150px;
  }
} */

.homepage .modal-header {
  border: none;
}
