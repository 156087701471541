.fc {
  /* background-color: #fff; */
  height: 88%;
}

.fc-view-container {
  height: 80% !important;
  background-color: white;
  /* overflow-y: scroll !important; */
  /* border-radius: 20px !important; */
}

.fc-scroller {
  height: 100% !important;
}

.fc-widget-content {
  /* background-color: lime; */
  height: 100% !important;
  /* overflow: hidden; */
}

.full-calendar__add-event-button {
  width: 25%;
}

.fc-button {
  background-color: var(--background-color-planning) !important;
  color: #333 !important;
  border: none !important;
}

.fc-list-view {
  border-width: 0px !important;
}

.fc-unthemed .fc-list-empty {
  background-color: #ffffff !important;
}

.fc-toolbar.fc-header-toolbar {
  padding: 15px 0 0 15px;
}

button.fc-button.fc-button-primary.add-button {
  margin-bottom: 15px;
}

.fc-toolbar h2 {
  font-size: 18px !important;
  text-transform: capitalize;
}
