$mobile-breakpoint: 1000px;

.body-lock {
  position: fixed;
  overflow: hidden;
}

#disclaimer-modal {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #000000c2;
  z-index: 40000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #16433f;

  h1 {
    font-size: 21px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--text-mworks);
    margin-top: 40px;
  }

  p {
    font-size: 16px;
    font-weight: 100;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--text-mworks);
    margin-top: 10px;
  }
  .disclaimer-logo {
    padding: 22px;
    img {
      width: 29px;
      height: 30px;
    }
  }

  .disclaimer-modal-body {
    width: 80vw;
    max-width: 800px;
    height: 500px;
    box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
    border-radius: 20px;
    border: none;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    text-align: center;
    .disclaimer-button-bar {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 20px;
      position: absolute;
      bottom: 0;
      align-items: center;
      .custom-dots {
        font-size: 1.8rem;
        color: #ddd;
        .active {
          color: #333;
        }
      }
      & > div {
        width: 30%;
      }
      & button {
        background: none;
        outline: none;
        border: none;
      }
      .carousel-back-button {
        order: -1;
      }
    }
    // eof .disclaimer-modal-body
  }

  .disclaimer-carousel-body {
    height: calc(100% - 50px);
    .BrainhubCarousel__dots {
      height: 0 !important;
      padding: 0 !important;
      margin: 0 !important;
      visibility: hidden !important;
    }
  }

  .disclaimer-carousel-card {
    width: 100%;
    height: 100%;
    padding: 0 120px;
    padding-top: 110px;
    img {
      width: 80%;
      max-width: 100%;
    }
    .font-weight-bold {
      font-weight: 800;
    }
    .padding-top {
      padding-top: 30px;
    }
  }

  .disclaimer-mworks-logo {
    img {
      width: 126px;
      margin: -20px;
    }
    .title {
      position: relative;
      top: 40px;
      font-size: 13px;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .disclaimer-modal-body {
      max-width: 85vw;
      height: 60vh;
      margin-bottom: 20%;
    }
    .disclaimer-carousel-card {
      padding: 0 22px;
    }
    .disclaimer-mworks-logo {
      padding: 20px 0;
      .title {
        position: relative;
        top: 23px;
        font-size: 13px;
      }
    }
  }
  // eof #disclaimer-modal
}
