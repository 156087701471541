.navbar-small {
  padding-top: 7px;
  width: 94vw;
  position: absolute;
  margin: auto;
  top: 0;
  right: 2vw;
  display: flex;
  flex-direction: column;
  /* z-index: 999 !important; */
  background: transparent !important;
}

div.bg-line svg {
  position: absolute;
  top: 70px;
  width: 100%;
}

.navbar-small a,
.navbar-small a:hover {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: 600;
}

.navbar-small .top-container {
  width: 100%;
  height: 50%;
  display: flex;
}

.navbar-small .left-container {
  width: 42%;
  display: flex;
  align-items: center;
}

.navbar-small .left-container .link-homepage {
  width: 100%;
}

.navbar-small .right-container {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.navbar-small .logout-link {
  padding: 0;
  margin: 0px 10px;
  color: var(--main-color-black);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.navbar-small a,
p {
  text-decoration: none;
  color: var(--main-color-black);
  font-size: 14px;
  font-weight: 600;
}

.navbar-small-logo {
  width: 51px;
  height: 36px;
}

.nav-item-waves {
  width: 100vw;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.nav-small-carousel {
  padding-top: 40px;
}

.mobile-nav-item {
  width: 105px;
  height: 75px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.BrainhubCarouselItem a {
  text-decoration: none;
}

.react-multi-carousel-dot-list {
  position: fixed;
  z-index: 101;
  width: 50vw;
  height: 60px;
  margin: auto;
  background: white;
}

.react-multi-carousel-dot-list a:active {
  text-decoration: none;
}

.link-mworks .mobile-nav-item {
  background-image: url('../../../assets/navbarSmall/cards/mworks-card.svg');
}

.link-resources .mobile-nav-item {
  background-image: url('../../../assets/navbarSmall/cards/resources-card.svg');
}

.link-network .mobile-nav-item {
  background-image: url('../../../assets/navbarSmall/cards/network-card.svg');
}

.link-wellbeing .mobile-nav-item {
  background-image: url('../../../assets/navbarSmall/cards/wellbeing-card.svg');
}

.link-strategies .mobile-nav-item {
  background-image: url('../../../assets/navbarSmall/cards/strategies-card.svg');
}

.link-planning .mobile-nav-item {
  background-image: url('../../../assets/navbarSmall/cards/planning-card.svg');
}

.link-mworks .mobile-nav-item .link-title {
  color: var(--text-mworks);
}

.link-resources .mobile-nav-item .link-title {
  color: var(--text-resources);
}

.link-network .mobile-nav-item .link-title {
  color: var(--text-network);
}

.link-wellbeing .mobile-nav-item .link-title {
  color: var(--text-wellbeing);
}

.link-strategies .mobile-nav-item .link-title {
  color: var(--text-strategies);
}

.link-planning .mobile-nav-item .link-title {
  color: var(--text-planning);
}

.link-title {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #16433f; /* Design mentions var(--text-color-mworks); but in the code that's a diff hex code */
  padding-top: 10px;
}

/* Custom dots styling */
.navbar-carousel .BrainhubCarousel__container .BrainhubCarousel__dots {
  position: fixed;
  z-index: 101;
  bottom: 0;
  left: 28vw;
  height: 60px;
  width: 46vw;
  padding: 0 !important;
  margin: 0;
  align-items: center;
  background: white;
}

.navbar-carousel .BrainhubCarousel__dots .BrainhubCarousel__dot {
  width: 10px;
  height: 10px;
  margin: 5px !important;
}

.navbar-carousel .BrainhubCarousel__dots .BrainhubCarousel__dot {
  background-color: var(--main-color-black);
  opacity: 0.4;
}

.navbar-carousel .BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background-color: var(--main-color-black);
  opacity: 0.4;
}

.navbar-carousel .BrainhubCarousel__dots .BrainhubCarousel__dot--selected {
  background-color: var(--main-color-black);
}
.navbar-carousel
  .BrainhubCarousel__dots
  .BrainhubCarousel__dot--selected:before {
  background-color: var(--main-color-black);
}

.link-mworks .mobile-nav-item .link-title {
  color: var(--text-mworks);
}

.link-resources .mobile-nav-item .link-title {
  color: var(--text-resources);
}

.link-network .mobile-nav-item .link-title {
  color: var(--text-network);
}

.link-wellbeing .mobile-nav-item .link-title {
  color: var(--text-wellbeing);
}

.link-strategies .mobile-nav-item .link-title {
  color: var(--text-strategies);
}

.link-planning .mobile-nav-item .link-title {
  color: var(--text-planning);
}

.link-title {
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  color: #16433f; /* Design mentions var(--text-color-mworks); but in the code that's a diff hex code */
  padding-top: 10px;
}

.nav-small-carousel .standalone-link-title {
  position: absolute;
  top: 13px;
  left: calc(0%);
  width: 100%;
}
