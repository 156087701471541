.homepage-small .BrainhubCarouselItem {
  height: 255px;
}

.BrainhubCarousel__dots {
  padding: 100px 20px 22px 0 !important;
}

.BrainhubCarousel__dot {
  border-radius: 50%;
  background-color: black;
  margin: 8px !important;
  padding: 3px !important;
}
.BrainhubCarousel,
.BrainhubCarousel__container,
.BrainhubCarousel .BrainhubCarousel__trackContainer {
  overflow: visible !important;
}

.homepage-small .middle-section .card {
  position: absolute;
  top: 0;
  background-color: transparent;
  left: 25%;
  text-align: center;
  align-items: center;
  padding: 7% 3%;
  border: transparent;
}

.homepage-small .middle-section .card__title {
  height: 28px;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.homepage-small .middle-section .card__logo-container {
  padding-top: 12px;
}

.homepage-small .middle-section .card__logo {
  width: 96px;
  height: 67px;
  object-fit: contain;
}

.homepage-small .middle-section .card__info-text {
  width: 224px;
  height: 66px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding-top: 10px;
}
.homepage-small .card__logo-container {
  display: flex;
  justify-content: center;
}

.homepage-small .card__box-container.bg-resources {
  background-image: url('../../../../assets/homepage/mobileHomePage/resurser.svg');
  background-repeat: no-repeat;
  width: 260px;
  height: 255px;
  padding: 20px;
}
.homepage-small .card__box-container.bg-network {
  background-image: url('../../../../assets/homepage/mobileHomePage/network.svg');
  background-repeat: no-repeat;
  width: 260px;
  height: 255px;
  padding: 20px;
}
.homepage-small .card__box-container.bg-wellbeing {
  background-image: url('../../../../assets/homepage/mobileHomePage/welling.svg');
  background-repeat: no-repeat;
  width: 260px;
  height: 255px;
  padding: 20px;
}
.homepage-small .card__box-container.bg-strategies {
  background-image: url('../../../../assets/homepage/mobileHomePage/strategier.svg');
  background-repeat: no-repeat;
  width: 260px;
  height: 255px;
  padding: 20px;
}
.homepage-small .card__box-container.bg-planning {
  background-image: url('../../../../assets/homepage/mobileHomePage/planering.svg');
  background-repeat: no-repeat;
  width: 260px;
  height: 255px;
  padding: 20px;
}

.homepage-small .goToPage {
  display: flex;
  justify-content: center;
  position: relative;
  top: -115px;
  right: 16px;
}
.homepage-small .goToPage-button {
  width: 260px;
  height: 40px;
  border-radius: 20px;
  box-shadow: 1px 1px 7px 0 rgba(73, 75, 82, 0.25);
  background-color: #ffffff;
  border: 0;
}

@media (min-width: 313px) {
  .carousel {
    width: 569px;
    right: 112px !important;
  }
}
@media (min-width: 333px) {
  .carousel {
    width: 569px;
    right: 104px !important;
  }

  .homepage-small .goToPage {
    right: 4px;
    top: -97px;
  }

  .BrainhubCarousel__dots {
    padding: 100px 7px 36px 0 !important;
  }
  .BrainhubCarousel__dots {
    margin: -30px;
  }
}

@media (min-width: 370px) {
  .carousel {
    width: 569px;
    right: 92px !important;
  }
}

@media (min-width: 375px) {
  .carousel {
    width: 569px;
    right: 96px !important;
  }
}

@media (min-width: 411px) {
  .carousel {
    width: 569px;
    right: 78px !important;
  }
}

@media (min-width: 427px) {
  .carousel {
    width: 569px;
    right: 60px !important;
  }
}

@media (min-width: 491px) {
  .carousel {
    width: 569px;
    right: 20px !important;
  }
}

@media (min-width: 563px) {
  .carousel {
    width: 569px;
    right: 12px !important;
  }
}

@media (min-width: 617px) {
  .carousel {
    width: 569px;
    right: -45px !important;
  }
}

@media (min-width: 755px) {
  .carousel {
    width: 569px;
    right: -132px !important;
  }
}

@media (min-width: 900px) {
  .carousel {
    width: 569px;
    right: -191px !important;
  }
}

@media (min-width: 1000px) {
  .carousel {
    width: 569px;
    right: -239px !important;
  }
}
