.position {
  top: 10%;
}
.cloud-modal .modal-body {
  height: auto;
}
.cloud-modal .form-check {
  align-items: center;
  display: flex;
}
.cloud-modal label.form-check-label {
  padding: 10px 0px 6px 10px;
}
.cloud-modal input[type='submit'] {
  margin: 20px;
}
.bubble {
  background: red;
  color: white;
  padding: 4px 12px;
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}
.bubble::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 2px;
  background: red;
  top: -1px;
  left: 50%;
}
.cloud-modal .exit-modal {
  font-size: 16px;
  color: #9d9d9d;
  background-color: transparent;
  border: none;
}
.cloud-modal .exit-modal:hover,
.cloud-modal .save:hover {
  font-size: 17px !important;
  font-weight: bold;
}

.cloud-modal .modal-footer {
  border-top: none;
  height: 70px;
  position: relative;
  left: 155px;
}
.cloud-modal .btn-danger:not(:disabled):not(.disabled):active {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

.cloud-modal .btn-danger:not(:disabled):not(.disabled):active:focus,
.cloud-modal .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem #fff;
}
.cloud-modal .save {
  border: none;
  font-size: 16px;
  background: transparent;
}
@media (max-width: 1100px) {
  .cloud-modal .modal-footer {
    left: 35px;
  }
}

