/* VARIABLES */

:root {
  --background-color-network: #fdc472;
  --background-color-resources: #ffe084;
  --background-color-strategies: #e78e9d;
  --background-color-wellbeing: #b2cded;
  --background-color-mworks: #99d4cf;
  --background-color-planning: #b6d98c;
  --text-color-resources: #f2c20d;
  --text-color-mworks: #6ea29e;
  --text-color-network: #e6903b;
  --text-color-wellbeing: #6c8bde;
  --text-color-strategies: #d16476;
  --text-color-planning: #7daa47;
  --main-color-black: #494b52;
  --text-color-mina-resources: #615122;
  --text-color-min-planering: #364c1c;
  --text-color-mitt-network: #67430b;
  --text-color-mitt-wellbeing: #32547d;
  --text-color-mina-strategies: #38171d;
  --text-mworks: #16433f;
  --text-resources: #674900;
  --text-network: #7d3d00;
  --text-wellbeing: #32547d;
  --text-strategies: #5b1420;
  --text-planning: #364c1c;
  --boxshadow: #e4e4e4;
}

* {
  font-family: 'Nunito', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* .form-control:disabled {
  background-color: #ffffff;
}
body {
  background-color: #fbfbfb;
}

.bold {
  font-weight: bold !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

/* Padding top and bottom */
.ptb-10 {
  padding: 10px 0;
}

.hidden {
  display: none;
}

/* Adding this rule here to work for entire page. Stops vertical scrolling while dragging on any carousels */
.BrainhubCarousel__container {
  touch-action: pan-x;
}
