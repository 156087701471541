.goals-wrapper {
  width: 100%;
  height: 80%;
  margin: 0 auto;
  font-size: 14px;
  padding: 0 16px;
  overflow: auto;

  .locked {
    input {
      background: none;
      border: none;
    }
  }

  .lists-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .heading {
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 5px;
    }
    .goals-first-input {
      margin-bottom: 0;
    }

    .list {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ol {
        padding-left: 15px;
        li {
          margin-bottom: 5px;
          input {
            width: 100%;
          }
        }
      }
    }
  }
}
