.custom-arrow {
  position: fixed;
  bottom: 0;
  z-index: 101;
  background: white;
  border: none;
  width: 28vw;
  height: 60px;
}

.custom-arrow:focus {
  outline: none;
}

.custom-left-arrow {
  left: 0;
}

.custom-right-arrow {
  right: 0;
}
