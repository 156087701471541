.App {
  width: 100%;
  /* height: 100vh; */
  margin: auto;
  box-sizing: border-box;
  padding: 0;
  overflow-x: hidden;
}

.background-container {
  z-index: -100 !important;
}

.loading-spinner-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
}

.loading-spinner {
  height: 98px;
  width: 98px;
}

.version {
  margin-left: 15px;
  color: gray;
}
