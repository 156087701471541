.homepage-small {
  width: 100vw;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.homepage-small .cloud-info {
  position: absolute;
  top: 93px;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.homepage-small a {
  display: block;
  width: 100%;
  text-align: center;
}

.homepage-small .cloud-title {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #24655f;
}
.homepage-small .cloud-text {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #24655f;
  max-width: 190px;
  margin: 0 auto;
}

@media not all and (min-resolution: 0.001dpcm) {
  .homepage-small .cloud-info {
    margin-top: 20px;
  }
}

.homepage-small p {
  margin: 0px 10px;
}
.homepage-small .mworks_logo {
  width: 32px;
  height: 28px;
  object-fit: contain;
}

.homepage-small a:hover {
  text-decoration: none;
}

.homepage-small .background-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.homepage-small .top-section-right {
  display: flex;
}

.homepage-small .admin {
  display: inline-flex;
}

.homepage-small .background-container img {
  width: 100%;
  object-fit: none;
  opacity: 0.85;
  height: 450px;
}

/******** NAVBAR ********/
.homepage-small .navbar {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 5vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 5%;
  z-index: 100;
}

.homepage-small .logout-link {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
/******** NAVBAR END ********/

/******** TOP SECTION ********/
.homepage-small .main-container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.homepage-small .top-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px 15px 15px;
  font-size: 14px;
}

.homepage-small .logo-section {
  display: flex;
  justify-content: center;
}

.homepage-small .logo-section img.logo {
  width: 273px;
  height: 188px;
  object-fit: contain;
}

.homepage-small .top-section .header {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color-mworks);
  text-align: center;
  padding: 0 5%;
}

.homepage-small .top-section .content {
  text-align: center;
  padding: 0 5% 1rem 5%;
  font-size: 16px;
  color: var(--main-color-black);
}

.homepage-small .top-section .read-more {
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.2);
  background-color: #fff;
  padding: 9px 28px;
  font-weight: bold;
  outline: none;
  border: none;
  color: var(--main-color-black);
}

.homepage-small .top-section .om-mworks {
  background-color: transparent;
  font-weight: bold;
  outline: none;
  border: none;
}

/******** TOP SECTION END ********/

/******** MIDDLE SECTION ********/
.homepage-small .middle-section {
  margin-top: 5%;
}
/******** MIDDLE SECTION END ********/

/******** BOTTOM SECTION ********/
.homepage-small .bottom-section {
  width: 100%;
  height: 175px;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.homepage-small .start-button {
  width: 310px;
  height: 55px;
  border-radius: 27.5px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  background-color: var(--main-color-black);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  /* margin: 10% 0; */
}
/******** BOTTOM SECTION END ********/

/******** MODAL ********/
.homepage-small .logo-small__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8% 3%;
}

.homepage-small .modal-body {
  height: 236px;
}

.homepage-small .modal-dialog {
  -webkit-transform: none;
  transform: none;
  width: 95%;
}

.homepage-small .logo-small__top-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1.5% 0;
}

.homepage-small .logo-small__bottom-row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 1.5% 0;
}

.homepage-small .logo-small__bottom-row .logo-small__logo {
  height: 75%;
}

.homepage-small .logo-small {
  height: 55px;
  width: 55px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.homepage-small .logo-small__logo {
  height: 50%;
}
/******** MODAL END ********/

/******** SPECIAL ********/
.homepage-small .mworks-sphere {
  height: 300px !important;
  width: 300px !important;
  background-color: var(--background-color-mworks);
  border-radius: 50% !important;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
}

.homepage-small .bg-mworks .card__info-text {
  font-size: 12px;
  /* background-color: hotpink; */
  margin-top: -10%;
  /* width: 80%; */
}
/******** SPECIAL END ********/

/******** MEDIA QUERIES ********/

@media (min-width: 450px) {
  .homepage-small .top-section .header {
    font-size: 26px;
  }

  /******** MODAL ********/
  .homepage-small .logo-small__top-row,
  .homepage-small .logo-small__bottom-row {
    width: 75%;
    /******** MODAL END ********/
  }
}

@media (min-width: 400px) and (max-width: 450px) {
  /******** MODAL ********/
  .homepage-small .logo-small__top-row,
  .homepage-small .logo-small__bottom-row {
    width: 90%;
    /******** MODAL END ********/
  }
}

/******** MEDIA QUERIES END ********/
