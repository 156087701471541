.mynetwork {
  width: 100vw;
  /* height: 100%; */
  min-height: 100vh;
  padding-top: 17.5vh !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-bottom: 80px;
}

.mynetwork .background-container {
  height: 40%;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-image: url('../../assets/homepage/mountain-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.mynetwork .main-container {
  height: 100%;
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

.mynetwork .main-container .mynetwork-title-container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* height: 25%; */
}

.mynetwork .main-container .mynetwork-title-container .textarea-title {
  margin: 0 0 0 -30rem;
  height: 100%;
}

.mynetwork .top-content {
  width: 70%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  /* background-color: lightseagreen; */
}

.mynetwork .mynetwork__grid-container {
  /* background-color: lightpink; */
  width: 95%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: lightgreen; */
}

.mynetwork .mynetwork__grid-container .grid__top-row,
.mynetwork .mynetwork__grid-container .grid__bottom-row {
  width: 100%;
  flex: 0 1;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* background-color: lightslategray; */
}

.mynetwork .mynetwork__grid-container .grid__middle-row {
  flex: 2;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* padding: 0; */
  /* background-color: lime; */
}

.mynetwork .grid__middle-row .left-column,
.mynetwork .grid__middle-row .right-column {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: magenta; */
}

.mynetwork .grid__middle-row .middle-column {
  flex: 2;
  /* width: 50%; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.mynetwork .grid__middle-row .me-square {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  font-size: 48px;
  font-weight: bold;
  color: #ffffff;
  background-color: var(--text-color-network);
  cursor: default;
}

/* .mynetwork .mynetwork__grid-container .grid__bottom-row {
  flex: 1;
} */

.mynetwork .grid-square {
  height: 110px;
  width: 146px;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  color: #ffffff;
  margin: 5px;
}

.mynetwork .grid-square p {
  /* background-color: lightyellow; */
  text-align: center;
}

.mynetwork .grid-square .name {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  padding-top: 15px;
  /* background-color: lightpink; */
}

.mynetwork .grid-square .title {
  flex: 2;
  font-size: 14px;
  /* background-color: lightgoldenrodyellow; */
  margin: 0;
}

/* .mynetwork .bottom-content {
  width: 68%;
  height: 10%;
} */

.mynetwork .main-content {
  flex: 2;
  height: 100%;
  display: flex;
}

/* .mynetwork .left-column {
} */

.mynetwork .left-content {
  height: 100%;
  width: 50%;
}

.mynetwork .right-content {
  height: 100%;
  width: 50%;
}

.mynetwork .center-column {
  flex: 1;
  height: 100%;
}
/* .mynetwork .right-column {
} */

.mynetwork .bottom-content {
  width: 68%;
  height: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 80px;
}

.mynetwork .new-contact-button-container {
  /* width: 250px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mynetwork .new-contact-button {
  width: 218px;
  height: 43px;
  border-radius: 21.5px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  color: var(--main-color-black);
  border: none;
  /* margin-bottom: -130px; */
}

.mynetwork .info-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1rem;
  cursor: pointer;
}

.mynetwork .modal-body {
  height: auto;
}

.mynetwork-mobile-grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: space-around;
}

.mynetwork-mobile-grid-container .grid-square {
  margin-bottom: 25px;
}

.mynetwork .my-network-info {
  width: 30%;
  border-radius: 20px 20px 20px 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: var(--background-color-network);
  padding: 2% 2% 50px;
  display: none;
  position: absolute;
  top: 18%;
  z-index: 999;
}

.my-network-info .info-header {
  display: flex;
  justify-content: space-between;
}

.my-network-info .info-header .btn-dark {
  margin-top: -80px;
}

.mynetwork .opened {
  display: block;
}

@media (max-width: 1300px) {
  .mynetwork .main-container {
    width: 70%;
    justify-content: center;
  }

  .mynetwork .grid-square {
    height: 100px;
    font-size: 14px;
  }
}
@media (max-width: 1200px) {
  .mynetwork .main-container {
    height: 80%;
    width: 75%;
    justify-content: center;
  }

  .mynetwork .grid-square {
    height: 100px;
    width: 125px;
    font-size: 12px;
  }

  .mynetwork .grid-square .title {
    font-size: 12px;
  }
}

@media (max-width: 1100px) {
  .mynetwork .grid-square {
    height: 100px;
    width: 100px;
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .mynetwork {
    min-height: 100%;
    height: auto;
    padding-top: 5vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
    justify-content: flex-start;
    flex-direction: column;
  }

  .mynetwork .main-container .mynetwork-title-container .textarea-title {
    padding: 5vh 0 0 10vw;
    margin-left: -25rem;
  }

  .mynetwork .top-content {
    width: 98%;
  }

  .mynetwork .main-container {
    overflow-y: hidden;
    padding-bottom: 1rem;
    height: 100%;
    width: 100%;
    z-index: 100;
    margin-top: 15px;
  }

  .mynetwork .grid-square {
    width: 150px;
    height: 104px;
    object-fit: contain;
    border-radius: 15px;
    padding: 10px;
  }
  .mynetwork .grid-square p {
    margin: 0;
  }

  .mynetwork .grid-square .name {
    padding-top: 10px;
  }

  .mynetwork .bottom-content {
    display: flex;
    justify-content: center;
  }

  .mynetwork .new-contact-button {
    width: 339px;
    height: 40px;
    box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  }
}

@media (max-width: 525px) {
  .mynetwork .main-container .mynetwork-title-container .textarea-title {
    width: 100%;
  }

  .mynetwork .main-container .mynetwork-title-container .textarea-title {
    padding: 8px 0px 8px 45px;
    margin: 0;
  }

  .mynetwork .my-network-info {
    width: 80%;
    top: 8%;
    padding: 3% 3% 50px;
  }

  .my-network-info .info-header .btn-dark {
    margin-top: -40px;
  }

  .mynetwork .grid__middle-row .me-square {
    height: 0;
    width: 0;
    font-size: 0;
  }
  .mynetwork .grid__middle-row .middle-column {
    flex: none;
    height: 120px;
    width: 120px;
    font-size: 30px;
    cursor: default;
  }

  .contact-card__edit-button {
    padding: 0 !important;
  }

  .contact-card__back-button {
    text-align: right !important;
    padding: 0 !important;
  }
}
