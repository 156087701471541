.rekopage {
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rekopage .main-container {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
}

/* TOP SECTION */
.rekopage .top-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

/* NAVBAR */
.rekopage .navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.rekopage .logout-link {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.rekopage .pagelink {
  color: #6ea29e !important;
  margin-left: -20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.rekopage .homepage-link {
  color: #6ea29e !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
}

.rekopage-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rekopage-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.rekopage-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.rekopage-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

.rekopage-content-container .pageheading {
  width: 190px;
}

/* MIDDLE SECTION */
.rekopage .middle-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* BOTTOM SECTION */
.rekopage .bottom-section {
  height: 23rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.rekopage .bottom-section .content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
  padding-top: 40px;
  background-repeat: no-repeat;
  background-position-y: 12px;
  background-position-x: 78px;
}

.rekopage .text-color-mworks {
  color: var(--text-color-mworks);
  font-weight: bold;
}

.rekopage .link-mworks a {
  color: #494b52;
}

.rekopage .main-div {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.rekopage .left-div {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.rekopage .right-div {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 1200px) {
  .rekopage .bottom-section {
    margin-left: 5px !important;
  }
}

.rekopage .bottom-section .card__circle {
  height: 58px !important;
  width: 58px !important;
  font-size: 20px !important;
}

@media (max-width: 1100px) {
  .rekopage .bottom-section {
    margin-left: 5px !important;
  }
}
@media (max-width: 1024px) {
  .rekopage .bottom-section {
    margin-left: 5px !important;
  }
}
@media (max-width: 768px) {
  .rekopage .bottom-section {
    margin-left: 5px !important;
  }
  .rekopage .main-div {
    width: 75%;
  }
  .rekopage .left-div {
    width: 37%;
  }
  .rekopage .right-div {
    width: 67%;
  }
}
