.textarea-container .textarea {
  margin: 15px 30px;
  font-size: 16px;
  width: 90%;
  display: flex;
  align-self: center;
}
.form-group {
  margin-bottom: 0;
}

.pen-icon-container img.iconify {
  margin-bottom: 5px;
}

.i-text {
  font-family: Garamond, Georgia, serif !important;
  font-weight: 500;
  padding: 3px 14px !important;
}

@media (max-width: 1100px) {
  .textarea-title,
  textarea {
    font-size: 14px !important;
  }
}
