@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));
  @return $randomNum;
}

.wordCloudSectionWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.myresources .cloudwords-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow-y: auto;
  margin: auto auto;
  padding: 15px;

  & > div {
    height: auto;
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    justify-items: center;
    align-self: center;
    line-height: 15px;

    @for $i from 1 through 30 {
      &:nth-child(#{$i}):nth-last-child(#{$i}) {
        /* The middle element in all odd counts */
        flex-basis: 100%;
        padding: 10px;
      }

      &:nth-child(#{$i + 1}):nth-last-child(#{$i}) {
        /* The "middle" element in all even counts */
        flex-basis: 100%;
        padding: 10px;
      }

      &:nth-child(-n + #{$i}) {
        flex-basis: #{randomNum(0%, 50%)};
        padding-top: #{randomNum(5, 15)}px;
        margin: #{randomNum(0, 5)}px;
      }

      &:nth-last-child(-n + 4) {
        flex-basis: #{randomNum(0%, 40%)};
        padding: #{randomNum(5, 15)}px;
        margin: 1px;
      }

      &:nth-child(-n + 4) {
        flex-basis: #{randomNum(0%, 40%)};
        padding-top: #{randomNum(5, 15)}px;
      }

      &:nth-last-child(-n + 3) {
        flex-basis: #{randomNum(0%, 33%)};
        padding: #{randomNum(1, 5)}px;
      }

      &:nth-child(-n + 3) {
        flex-basis: #{randomNum(0%, 33%)};
        padding: #{randomNum(1, 5)}px;
      }

      &:nth-last-child(-n + 2) {
        flex-basis: #{randomNum(0%, 43%)};
        padding: 0;
        padding: #{randomNum(5, 15)}px;
      }

      &:nth-child(-n + 2) {
        flex-basis: #{randomNum(0%, 33%)};
        margin: 1px;
        margin-top: #{randomNum(5, 15)}px;
      }

      &:nth-child(3n) {
        flex-basis: 0%;
        padding: 0;
        margin: 2px;
      }

      &:first-child {
        flex-basis: 100%;
        justify-content: flex-start;
        padding-left: 20%;
      }

      &:last-child {
        flex-basis: 100%;
        justify-content: flex-end;
        padding-right: 20%;
      }
    }
  }

  .editable-word {
    cursor: pointer;
  }
  .placeholder {
    flex-basis: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
}

.color-1 {
  color: #7a4ac0;
}

.bg-color-1 {
  background-color: #7a4ac0;
}

.color-2 {
  color: #185ea1;
}

.bg-color-2 {
  background-color: #185ea1;
}

.color-3 {
  color: #de9d00;
}

.bg-color-3 {
  background-color: #de9d00;
}

.color-4 {
  color: #579c06;
}

.bg-color-4 {
  background-color: #579c06;
}

.color-5 {
  color: #b31b35;
}

.bg-color-5 {
  background-color: #b31b35;
}

.size-1 {
  font-size: 10px;
  font-weight: 100;
}

.size-2 {
  font-size: 14px;
  font-weight: 300;
  flex-basis: 20%;
}

.size-3 {
  font-size: 18px;
  font-weight: 100;
  flex-basis: 25%;
}

.size-4 {
  font-size: 23px;
  font-weight: bold;
  flex-basis: 33%;
}

.size-5 {
  font-size: 30px;
  font-weight: bold;
}
