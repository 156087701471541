.main-container .card {
  border: none;
  width: 275px;
  border-radius: 6px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  background-color: #ffffff;
  padding: 5px 8px;
  font-size: 16px;
}
.card .card-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: -12px;
}

.card .card-title-container h5 {
  margin: 0;
}

.card .card-title-container .card-title-icon-container {
  cursor: pointer;
}

.card .card-title-container .delete-icon {
  font-size: 20px;
}

.card .list-group-item {
  border: none;
}

.contact-card__button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 70px;
}
.card .card-title-icon-container {
  display: flex;
  justify-content: flex-end;
}
.card .bold.note-title {
  display: flex;
}
.contact-card__back-button {
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  border: none;
  background-color: transparent !important;
  width: 50px;
}

button.bg-danger.contact-card__back-button:hover,
button.contact-card__edit-button:hover {
  background-color: transparent !important;
  font-size: 18px;
}

.contact-card__edit-button {
  padding-right: 20px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #9d9d9d;
  background-color: transparent;
  border: none;
}

.contact-card__delete-button {
  width: 60px;
  height: 40px;
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--main-color-black);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 2%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media (max-width: 992px) {
  .main-container .card {
    margin-top: 30px;
  }
}
