.textarea-container .textarea {
  margin: 0px 30px 20px 30px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-self: center;
  color: #000000;
}
.disabled {
  border: none !important;
  color: #6c757d;
}

.textarea::-webkit-input-placeholder {
  color: #bebebe;
}

.textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #bebebe;
}

.textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #bebebe;
}

.textarea:-ms-input-placeholder {
  color: #bebebe;
}

.form-group {
  margin-bottom: 0;
}

@media (max-width: 1100px) {
  .textarea-title,
  textarea {
    font-size: 14px !important;
  }
  .textarea-container .textarea {
    width: 95%;
    margin: 0;
  }

  .disabled {
    font-size: 14px !important;
  }
}
