.color-circle.bg-color-1.active {
  /* border: 2px solid #4e64b9; */
  border: 2px solid #000;

}
.color-circle.bg-color-2.active {
  /* border: 2px solid #3b757a; */
  border: 2px solid #000;
}
.color-circle.bg-color-3.active {
  /* border: 2px solid #a49742; */
  border: 2px solid #000;
}
.color-circle.bg-color-4.active {
  /* border: 2px solid #688737; */
  border: 2px solid #000;
}
.color-circle.bg-color-5.active {
  /* border: 2px solid #9c2851; */
  border: 2px solid #000;
}
