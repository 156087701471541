.new-strategies {
  max-height: 80%;
  width: 98%;
  overflow-y: scroll;
  padding: 0px 0px 3% 3%;
  border: none;
  box-sizing: border-box;
  margin: 0px 0px 0px 4px;
}

.new-strategies h5,
.new-strategies h6 {
  font-weight: bolder;
}

@media (max-width: 1100px) {
  .new-strategies {
    height: 62%;
    width: 94%;
    margin: 5px 0px 0px 10px;
  }
}
@media (max-width: 992px) {
  .new-strategies {
    max-height: 89%;
    height: 78%;
  }
}
