.addclient {
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addclient .main-container {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
}

/* TOP SECTION */
.addclient .top-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

/* NAVBAR */
.addclient .navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.addclient .logout-link {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.addclient .homepage-link {
  color: #6ea29e;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
}

.addclient-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addclient-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.addclient-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.addclient-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

.addclient-content-container .pageheading {
  width: 365px;
}

/* MIDDLE SECTION */
.addclient .middle-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* BOTTOM SECTION */
.addclient .bottom-section {
  height: 23rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.addclient .register-form-container {
  width: 100%;
}

.addclient .cookie-accept-container {
  text-align: center;
}

.addclient .cookie-accept-container p {
  text-align: left;
}

.addclient .info-icon-container {
  height: 20px;
  width: 20px;
}

.addclient .link-mworks a {
  color: #494b52;
}

@media screen and (max-width: 1200px) {
  .addclient .bottom-section {
    height: 15rem;
  }
}

@media screen and (max-width: 1024px) {
  .addclient .bottom-section {
    height: 15rem;
  }
}

@media screen and (max-width: 768px) {
  .addclient .bottom-section {
    height: 15rem;
  }
  .addclient h1 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .addclient .bottom-section {
    height: 15rem;
  }
  .addclient h1 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }
}
