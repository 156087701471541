.myresources {
  width: 100vw;
  /* height: 100vh; */
  padding-top: 17.5vh !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.myresources .background-container {
  height: 40%;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-image: url('../../assets/homepage/mountain-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.myresources .main-container {
  height: 87%;
  width: 68%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.myresources .left-container {
  width: 50%;
  /* height: 600px; */
  display: flex;
  flex-direction: column;
}

.myresources .left-top-container {
  width: 100%;
  height: 260px;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: #fff;
  padding: 0 3%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: auto 10px !important;
}

.myresources .left-top-header {
  display: flex;
  justify-content: space-between;
}

.myresources .left-bottom-container {
  margin-top: 30px;
  width: 100%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: #fff;
  padding: 0 3%;
}

.myresources .left-bottom-container .label-info-clouds {
  align-self: flex-start;
}

.myresources .left-bottom-container .words-cloud-container {
  max-width: 475px;
  width: 470px;
  height: 300px;
  align-self: flex-end;
  object-fit: contain;
}

.left-container .textarea-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
  padding: 0;
}

.textarea-container textarea {
  flex: 1 1 auto;
  height: auto;
  width: 100%;
  border: none;
}

.myresources textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: 0px 0 0 2px var(--background-color-resources) !important;
}

.myresources .right-container {
  margin-left: 30px;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.myresources .right-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border-radius: 20px;
  border: none;
  background-color: #fff;
  z-index: 1;
}

.right-container .textarea-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0;
}

.myresources label {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0 0 15px;
  align-self: flex-start;
}

.textarea {
  resize: none;
}

.div-strengths {
  height: 85%;
  width: 50%;
  margin: 10px 5px 5px 5px;
}

.b-strengths {
  margin: 0px 0px 0px 10px;
}

.ul-strengths {
  height: 90%;
  overflow: hidden;
  overflow-y: scroll;
  list-style-type: none;
  padding: 0px 0px 0px 10px;
  word-break: break-all;
}

.btn-add-float {
  float: right;
}

.plus-icon-container {
  float: right;
}

.plus-icon-img {
  margin-bottom: 8px;
}

.myresources .my-work-profile-info,
.my-strengths-list-info {
  /* margin-top: 30px; */
  width: 100%;
  border-radius: 20px 20px 0 0;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: var(--background-color-resources);
  padding: 3% 3% 50px;
  display: none;
}

.opened {
  display: block !important;
  flex-direction: column;
  margin-bottom: -50px;
}

.info-icon-container {
  font-family: Garamond, Georgia, serif !important;
  font-weight: 500;
  padding: 3px 14px !important;
  font-size: x-large;
  background-color: #ffff;
  border-radius: 25px;
  width: 37px;
  height: 37px;
  box-shadow: 1px 1px 7px 0 #e4e4e4;
  padding: 3px 9px;
}

@media (max-width: 1100px) {
  .myresources {
    font-size: 14px !important;
  }
  .div-strengths {
    height: 100%;
  }
  .ul-strengths {
    height: 90%;
  }
  .myresources .left-container {
    padding-top: 20px;
  }
  .myresources .right-container {
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .myresources {
    padding-top: 5vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
  }

  .myresources .main-container {
    overflow-y: hidden;
    padding-bottom: 1rem;
    height: 100%;
    width: 85%;
    flex-direction: column;
  }

  .myresources .left-container {
    width: 100%;
    height: 600px;
    margin-top: 30px;
    padding-top: 0;
    justify-content: space-between;
  }

  .myresources .left-top-container {
    min-height: 50%;
    padding: 5px 5px;
  }

  .myresources .left-bottom-container {
    min-height: 50%;
    padding: 5px 5px;
  }

  .myresources .right-container .textarea-container {
    padding: 0 0 8px 0;
  }

  .textarea-container textarea {
    height: 100%;
  }

  .myresources .right-container {
    width: 100%;
    margin-left: 0;
    margin-top: 65px;
  }
}
