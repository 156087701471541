.myplanning {
  width: 100vw;
  /* height: 100vh; */
  padding-top: 17.5vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.myplanning .background-container {
  height: 40%;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-image: url('../../assets/homepage/mountain-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.myplanning .main-container {
  height: 87%;
  width: 68%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.myplanning .left-container {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.myplanning .left-top-container {
  width: 100%;
  height: 320px;
  /* flex: 1 1 auto; */
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: #fff;
  padding: 0 3%;
  margin-bottom: 30px;
}

.myplanning .left-bottom-container {
  width: 100%;
  /* height: 50%; */
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border-radius: 20px;
  border: none;
  background-color: #fff;
  padding: 0 3%;
}

.myplanning .left-container .textarea-container {
  display: flex;
  flex-direction: column;
  /* height: 100%; */
  width: 100%;
}

.myplanning .textarea-container textarea {
  flex: 1 1 auto;
  height: auto;
  width: 100%;
  border-radius: 20px;
  border: none;
}

.myplanning textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: 0px 0 0 2px var(--background-color-planning) !important;
}

.myplanning .right-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
  margin-left: 30px;
}

.myplanning .right-container-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 600px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border-radius: 20px;
  border: none;
  background-color: #fff;
}

.myplanning .right-container .textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2%;
  margin: 0;
}

.myplanning .calendar-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.myplanning label {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0 0 15px;
  display: flex;
}

.textarea {
  resize: none;
}
.form-group {
  margin-bottom: 0;
}

.myplanning .my-todos-info,
.my-goals-info,
.my-calendar-info {
  /* margin-top: 30px; */
  width: 100%;
  border-radius: 20px 20px 0 0;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: var(--background-color-planning);
  padding: 3% 3% 50px;
  display: none;
  transition-duration: 1s;
}

.opened {
  display: block !important;
  flex-direction: column;
  margin-bottom: -50px;
}

@media (max-width: 1100px) {
  .myplanning {
    font-size: 14px !important;
  }
  .myplanning .left-container .textarea-container {
    padding: 0 5px;
  }
  .myplanning .right-container {
    margin-top: 15px;
  }
  .myplanning .left-container {
    padding-top: 10px;
    margin-top: 15px;
  }
  .myplanning .left-bottom-container {
    padding: 15px 0px;
  }
  .myplanning.left-top-container {
    padding: 15px 0;
  }
  textarea#myGoals {
    margin-bottom: 15px;
  }
}

@media (max-width: 992px) {
  .myplanning {
    padding-top: 5vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
  }

  .myplanning .main-container {
    overflow-y: hidden;

    padding-bottom: 1rem;

    flex-direction: column;
    height: 100%;
    width: 85%;
  }

  .myplanning .left-container {
    width: 100%;
    height: 50%;
    margin-top: 20px;
    justify-content: space-between;
  }

  .myplanning .left-top-container {
    min-height: 50%;
    padding: 0;
  }

  .myplanning .left-bottom-container {
    min-height: 50%;
    padding: 0;
  }

  .myplanning .textarea-container textarea {
    height: 100%;
  }

  .myplanning .right-container {
    width: 100%;
    /* height: 600px; */
    justify-content: space-between;
    margin-top: 60px;
    margin-left: 0;
    margin-top: 35px;
  }
}
