.mywellbeing {
  width: 100vw;
  /* height: 100vh; */
  padding-top: 17.5vh !important;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.mywellbeing .background-container {
  height: 40%;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 1;
  background-image: url('../../assets/homepage/mountain-2.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.mywellbeing .main-container {
  height: 87%;
  width: 68%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.mywellbeing .left-container {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.mywellbeing .left-top-container,
.left-middle-container {
  width: 100%;
  /* min-height: 50%; */
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: #fff;
  padding: 0 3%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  z-index: 1;
}

.mywellbeing .left-top-container-header,
.left-middle-container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mywellbeing #kaltura_player {
  flex-grow: 1;
  max-height: 80%;
  border-radius: 20px;
  margin-bottom: 10px;
}

.mywellbeing .feelings-info,
.tell-others-info,
.video-max-info,
.video-sara-info {
  /* margin-top: 30px; */
  width: 100%;
  border-radius: 20px 20px 0 0;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: var(--background-color-wellbeing);
  padding: 3% 3% 50px;
  display: none;
  z-index: 0;
}

.opened {
  display: block !important;
  flex-direction: column;
  /* height: 300px !important; */
  margin-bottom: -40px;
}

.mywellbeing .left-bottom-container {
  width: 100%;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
  background-color: #fff;
  padding: 0 3%;
  z-index: 1;
}

.mywellbeing .left-bottom-container .button-link {
  background-color: var(--main-color-black);
  color: #fff;
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  padding: 2% 8%;
  font-weight: bold;
}

/* .mywellbeing .textarea-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
} */

/* .mywellbeing .textarea-container textarea {
  flex: 1 1 auto;
  height: auto;
  width: 100%;
} */

.mywellbeing textarea:focus {
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: 0px 0 0 2px var(--background-color-wellbeing) !important;
}

.mywellbeing .right-container {
  margin-left: 30px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mywellbeing .right-container-inner {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* height: 100%; */
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border-radius: 20px;
  border: none;
  background-color: #fff;
  z-index: 1;
}

.mywellbeing .right-container .textarea-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 2%;
  margin: 0;
}

/* .mywellbeing .right-container textarea {
  height: 93.5%;
} */

.mywellbeing label {
  font-size: 16px;
  font-weight: bold;
  padding: 15px 0 0 15px;
}

.textarea {
  resize: none;
}

.mywellbeing .left-bottom-container .top-margin {
  padding-bottom: 15px;
}

.mywellbeing .left-bottom-container .btn-container {
  margin-top: 0px;
}

.mywellbeing .right-container-inner .btn-container {
  margin-top: 10px;
}

@media (min-width: 1101px) {
  .mywellbeing .left-top-container,
  .left-middle-container {
    min-height: 282px;
  }
}

@media (max-width: 1100px) {
  .mywellbeing {
    font-size: 14px !important;
  }
  .mywellbeing .textarea-container {
    padding: 0 5px;
  }
  .mywellbeing .left-container {
    padding-top: 10px;
  }
  .mywellbeing .right-container {
    margin-top: 15px;
  }
  .mywellbeing .left-bottom-container {
    padding: 15px 0px;
  }
}

@media (max-width: 992px) {
  .mywellbeing {
    min-height: 200vh;
    padding-top: 5vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
  }

  .mywellbeing .main-container {
    overflow-y: hidden;
    padding-bottom: 1rem;
    height: 100%;
    width: 85%;
    flex-direction: column;
  }

  .mywellbeing .left-container {
    padding-top: 0.8rem;
    width: 100%;
    /* height: 60%; */
    margin-top: 20px;
    justify-content: space-between;
  }

  .mywellbeing .left-bottom-container {
    padding: 0;
  }

  .mywellbeing #kaltura_player {
    display: block;
    height: 100%;
    padding: 1px;
    border-radius: 20px !important;
    overflow: hidden;
    margin-bottom: 10px;
  }

  .mywellbeing .right-container {
    width: 100%;
    /* height: 40%; */
    margin-left: 0;
    margin-top: 35px;
  }

  .mywellbeing .right-container .textarea-container {
    padding: 5px;
  }
}
