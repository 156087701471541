.adminpage {
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminpage .main-container {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
}

/* TOP SECTION */
.adminpage .top-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

/* NAVBAR */
.adminpage .navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.adminpage .logout-link {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

/* multiple styling? */
.adminpage .pagelink {
  color: #6ea29e;
  margin-left: -20px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.adminpage .pagelink {
  color: #6ea29e !important;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
}

/* MIDDLE SECTION */
.adminpage .middle-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.adminpage-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.adminpage-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.adminpage-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.adminpage .link-mworks a {
  color: #494b52;
}

.adminpage-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

/* BOTTOM SECTION */
.adminpage .bottom-section {
  height: 23rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.adminpage .main-div {
  width: 35%;
  display: flex;
  justify-content: space-between;
}

.adminpage .left-div {
  width: 25%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.adminpage .center-div {
  width: 32%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.adminpage .right-div {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.adminpage .text-color-mworks {
  color: var(--text-color-mworks);
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .adminpage .main-div {
    width: 30%;
  }
}

@media screen and (max-width: 1024px) {
  .adminpage .main-div {
    width: 35%;
  }
}

@media screen and (max-width: 768px) {
  .adminpage .main-div {
    width: 45%;
  }
  .adminpage .left-div {
    width: 40%;
  }
  .adminpage .right-div {
    width: 60%;
  }
}

@media screen and (max-width: 420px) {
  .adminpage-content-container .pageheading {
    width: 146px;
  }
  .adminpage .h1,
  h1 {
    font-size: 1.8rem;
  }
  .adminpage .main-div {
    width: 95%;
  }
  .adminpage .left-div {
    width: 40%;
  }
  .adminpage .right-div {
    width: 60%;
  }
}
