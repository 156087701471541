.event-form {
  /* width: 100%; */
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; */
  /* background-color: lightpink; */
  /* margin-top: 20%; */
  padding: 6% 0 0 0;
  height: 100%;
}

.event-form .event-form__header {
  color: var(--text-color-network);
  font-size: 16px;
  font-weight: bold;
  width: 60%;
  margin: auto 0;
  padding: 1% 1%;
}

.event-form__form-container {
  width: 100%;
  height: 95%;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  background-color: #ffffff;
  padding: 2%;
  margin: 0 auto 2% auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event-form label {
  font-size: 12px;
  font-weight: 600;
  line-height: 2;
  color: #373a3c;
}

.event-form input {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  font-size: 14px;
}

.event-form .react-datepicker__input-container input {
  width: 100px;
  text-align: center;
}

.event-form small {
  padding-left: 5px;
}

.event-form .email-color-container {
  padding: 0;
}

.event-form .color-picker-container label {
  margin-bottom: 1%;
}

.event-form .color-container {
  /* background-color: lightsalmon; */
  height: 30%;
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-form .color-circle {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  cursor: pointer;
}

.event-form #description {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  outline: none;
  resize: none;
  padding: 3%;
  box-sizing: border-box;
  font-size: 14px;
}

.event-form .submit-button-container {
  width: 100%;
  margin: 3% auto;
  display: flex;
  justify-content: flex-end;
  /* padding-right: 3%; */
}

.event-form .submit-button {
  flex: 1;
  /* width: 126px; */
  height: 43px;
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--main-color-black);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  /* padding: 1% 5%; */
  border: none;
}
.event-form .cancel-button {
  flex: 1;
  /* width: 126px; */
  height: 43px;
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 2%;
  border: none;
}

.event-form .delete-button {
  /* min-width: 10px; */
  flex: 4;
  height: 43px;
  border-radius: 21.5px;
  box-shadow: 2px 6px 16px 0 rgba(0, 0, 0, 0.15);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  margin-right: 2%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  font-size: 14px;
}
.event-form .delete-button span {
  margin: 0 10px;
}
.event-form .submit-button:disabled {
  background-color: rgba(0, 0, 0, 0.3);
  color: #e1e1e1;
}

.event-form .submit-button:focus {
  outline: none;
}

@media (max-width: 1200px) {
  .event-form .color-container {
    width: 60%;
  }
}

@media (max-width: 992px) {
  .event-form {
    padding: 3%;
  }

  .event-form .event-form__header {
    width: 100%;
    padding: 0 2%;
  }

  .event-form__form-container {
    width: 100%;
    border-radius: 20px;
    box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
    background-color: #ffffff;
    padding: 2%;
  }

  .event-form .submit-button-container {
    width: 100%;
    /* margin: 3% auto; */
    display: flex;
    /* justify-content: space-around; */
    padding-right: 0;
  }

  .event-form .submit-button {
    width: 30%;
  }

  .event-form .cancel-button {
    width: 30%;
  }
  .event-form .delete-button {
    justify-content: center;
    padding: 0 5px;
    max-width: 35%;
    font-size: 12px;
  }
  .event-form .delete-button span {
    margin: 0 5px;
  }
  .event-form .color-container {
    width: 15%;
  }
}

@media (max-width: 850px) {
  .event-form .color-container {
    width: 20%;
  }
}

@media (max-width: 620px) {
  .event-form .color-container {
    width: 35%;
  }
}

.event-form .pink {
  background-color: #dfb0d4;
}

.event-form .blue {
  background-color: #84bcdd;
}

.event-form .green {
  background-color: #81bb94;
}

.event-form .purple {
  background-color: #af92d1;
}

.event-form .darkblue {
  background-color: #7d90d1;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.intimation-message {
  color: #b6d98c;
  font-size: 12px;
}
