.register {
  max-width: 992px;
  margin: 0 auto;
  overflow: hidden;
}

.register-content-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 20px auto;
}

.register-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.register-content-container .circle-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.register-content-container .circle-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

.register-container-header-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}

.register-form-container {
  width: 100%;
}

.cookie-accept-container {
  text-align: center;
}

.cookie-accept-container p {
  text-align: left;
}

.info-icon-container {
  height: 20px;
  width: 20px;
}

@media screen and (max-width: 930px) {
  .register-container-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
}

@media screen and (max-width: 420px) {
  .register-container-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  .register {
    max-width: 992px;
    margin: 0 auto;
    overflow-y: scroll;
  }
}
