.addreko {
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addreko .main-container {
  height: 90%;
  width: 90%;
  display: flex;
  flex-direction: column;
  z-index: 100 !important;
}

/* TOP SECTION */
.addreko .top-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

/* NAVBAR */
.addreko .navbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.addreko .logout-link {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.addreko .homepage-link {
  color: #6ea29e;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
}

.addreko-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.addreko-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.addreko-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.addreko-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

.addreko-content-container .pageheading {
  width: 310px;
}

/* MIDDLE SECTION */
.addreko .middle-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

/* BOTTOM SECTION */
.addreko .bottom-section {
  height: 23rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.addreko .register-form-container {
  width: 100%;
}

.addreko .cookie-accept-container {
  text-align: center;
}

.addreko .cookie-accept-container p {
  text-align: left;
}

.addreko .info-icon-container {
  height: 20px;
  width: 20px;
}

.addreko .link-mworks a {
  color: #494b52;
}

@media (max-width: 1200px) {
  .addreko .bottom-section {
    margin-left: 5px !important;
  }
}

@media (max-width: 1100px) {
  .addreko .bottom-section {
    margin-left: 5px !important;
  }
}
@media (max-width: 1024px) {
  .addreko .bottom-section {
    margin-left: 5px !important;
  }
}
@media screen and (max-width: 930px) {
  .addreko .register-container-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
}

@media screen and (max-width: 420px) {
  .addreko .register-container-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
