.my-way-back-wrapper {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 16px;

  // Spacing beween each section
  & > div {
    margin-bottom: 30px;
  }

  .pros-and-cons {
    display: flex;
    min-height: 25%;
    max-height: 130px;
    overflow: auto;
    & > div {
      flex-basis: 50%;
    }
    & ul {
      list-style-type: none;
      padding: 0;
      li {
        display: flex;
        outline: none;
        justify-content: space-between;
        align-items: center;
        word-wrap: none;
        border-bottom: 1px solid #eee;
        padding: 4px 0;
        img {
          display: block;
          margin-left: 7px;
        }
      }
    }
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .add-list-item-btn {
      color: blue;
    }
    .heading-group {
      padding-right: 10px;
      .highlight {
        font-weight: bold;
      }
    }
    input {
      border: none;
      border-bottom: 1px solid blue;
      border-radius: 0;
      outline: none;
    }
  }

  .text-area-custom {
    flex-grow: 1;
    padding-bottom: 25px;
    #myWayBack {
      margin: 0;
      padding: 0;
      overflow: auto;
      flex-basis: 230px;
    }
    .textarea-container {
      padding: 0;
      margin: 0;
      .textarea {
        width: 99%;
      }
    }
  }

  .draggable-slider {
    .rc-slider {
      margin-left: 6px;
      max-width: 90%;
      .rc-slider-handle {
        border: solid 2px #1976d3;
        height: 25px;
        width: 25px;
        color: white;
        text-align: center;
        font-size: 12px;
        top: 1px;
      }
    }

    .rc-slider-disabled {
      background: none;
      .rc-slider-handle {
        background-color: #1976d3;
        border: solid 2px #1976d3;
      }
      .rc-slider-track {
        background: #1976d3;
      }
    }
  }

  .custom-text-area {
    padding-bottom: 20px;
    textarea {
      margin: 0 auto;
      padding: 0;
      width: 99%;
      height: 100%;
    }
  }
  // General rules for entire component
  .heading {
    font-weight: bold;
    margin-bottom: 10px;
    background: white;
    border-bottom: 1px solid #ededed;
    padding-bottom: 5px;
  }

  .sticky {
    position: -webkit-sticky; /* Safari specific */
    position: sticky;
    top: 0;
  }

  .show-cursor {
    cursor: pointer;
  }
}
