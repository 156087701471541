.contact-form {
  width: 550px;
  border-radius: 6px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  background-color: #ffffff;
}

.contact-form .contact-form__header {
  margin: 0;
}

.form-group {
  margin-bottom: 0;
}

.contact-form__form-container {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  background-color: #ffffff;
  padding: 15px 18px;
  margin: 0 auto 2% auto;
}

.contact-form label {
  font-size: 12px;
  font-weight: 600;
  line-height: 2;
  color: #656564;
  padding-top: 17px;
}

.contact-form input {
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  font-size: 14px;
}

.contact-form small {
  padding-left: 5px;
  display: inherit;
  font-size: 14px;
  line-height: normal;
}

.contact-form .email-color-container {
  padding: 0;
}

.contact-form .color-picker-container label {
  margin-bottom: 1%;
}

.contact-form .color-container {
  width: 100%;

  justify-content: space-between;

  display: flex;
  padding: 0px 0px 0px 3px;
}

.contact-form .color-circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  cursor: pointer;
}

.contact-form #description {
  width: 100%;
  border-radius: 4px;
  border: solid 1px #e1e1e1;
  outline: none;
  resize: none;
  padding: 3%;
  box-sizing: border-box;
  font-size: 14px;
}

.contact-form .submit-button-container {
  width: 100%;
  margin: 3% auto;
  display: flex;
  justify-content: flex-end;
  padding-right: 3%;
  height: 25px;
}

.contact-form .submit-button,
.contact-form .cancel-button {
  background-color: transparent !important;
  font-size: 16px;
  font-weight: 600;
  border: none;
  color: #000000;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  margin-left: 22px;
  width: 70px;
}
.contact-form .cancel-button {
  color: #9d9d9d !important;
}

.contact-form .submit-button:focus,
.contact-form .submit-button:hover,
.contact-form .cancel-button:focus,
.contact-form .cancel-button:hover {
  font-weight: 900;
  background-color: transparent !important;
  font-size: 18px;
}

@media (max-width: 992px) {
  .contact-form {
    padding: 5%;
    height: 100%;
    width: auto;
  }

  .contact-form .contact-form__header {
    width: 100%;
    padding: 0 2%;
  }

  .contact-form .submit-button {
    margin-left: 0px;
  }

  .contact-form__form-container {
    width: 100%;
    border-radius: 6px;
    box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
    background-color: #ffffff;
    opacity: 0.85;
    padding: 4%;
  }

  .contact-form .submit-button-container {
    width: 100%;
    margin: 3% auto;
    display: flex;
    justify-content: space-around;
  }

  .contact-form .submit-button {
    width: 45%;
  }

  .contact-form .cancel-button {
    width: 45%;
  }

  .contact-form .color-container {
    width: 95%;
    padding-left: 15px;
  }

  .contact-form .email-color-container {
    padding: 0;
    margin-top: -14px;
    margin-bottom: 20px;
  }
}

.contact-form .pink {
  background-color: #dfb0d4;
}

.color-circle.pink.active {
  border: solid 2px #690f55;
}

.contact-form .blue {
  background-color: #84bcdd;
}
.color-circle.blue.active {
  border: solid 2px #1b5d63;
}

.color-circle.blue.active {
  border: solid 2px #1b5d63;
}

.contact-form .green {
  background-color: #81bb94;
}
.color-circle.green.active {
  border: solid 2px #15602d;
}

.color-circle.green.active {
  border: solid 2px #15602d;
}

.contact-form .purple {
  background-color: #af92d1;
}
.color-circle.purple.active {
  border: solid 2px #40186f;
}

.color-circle.purple.active {
  border: solid 2px #40186f;
}

.contact-form .yellow {
  background-color: #ebd17b;
}

.color-circle.yellow.active {
  border: solid 2px #685718;
}
