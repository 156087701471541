/* MIDDLE SECTION */
.sessions-middle-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sessions-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sessions-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.sessions-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.sessions-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

/* BOTTOM SECTION */
.sessions-bottom-section {
  height: 23rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.sessions-bottom-section .data-container {
  height: auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.sessions-center-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
}

.left-top-container {
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  position: relative;
}

.session-heading-text {
  font-size: 18px;
  font-weight: bold;
  float: left;
  display: table-column;
  padding: 10px;
  width: 45%;
  background-color: #99d4cf;
}

.total-sessions {
  width: 55%;
}

.session-column-userdata {
  font-size: 14px;
  color: #494b52;
  float: left;
  display: table-column;
  padding: 10px;
  width: 50%;
}

.underlined-text {
  text-decoration: underline;
  cursor: pointer;
  float: left;
}

.underlined-text:hover {
  color: #6ea29e;
}

/* MEDIA STYLES */
@media (max-width: 1200px) {
  .sessions-bottom-section {
    margin-left: 5px !important;
  }
}

.sessions-bottom-section .card__circle {
  height: 58px !important;
  width: 58px !important;
  font-size: 20px !important;
}

@media (max-width: 1100px) {
  .sessions-bottom-section {
    margin-left: 5px !important;
  }
}
@media (max-width: 1024px) {
  .sessions-bottom-section {
    margin-left: 5px !important;
  }
}
@media screen and (max-width: 768px) {
  .sessions-bottom-section .data-container {
    width: 100%;
    height: auto;
    margin-top: 0%;
    margin-left: 5px !important;
  }
  .session-heading-text {
    width: 50%;
    font-size: 16px;
  }
  .session-column-userdata {
    width: 40%;
  }
  .underlined-text {
    width: 60%;
  }
  .left-top-container {
    width: 100%;
    overflow: hidden;
    padding: 0%;
    margin-right: 0%;
    padding-right: 0%;
  }
}
@media screen and (max-width: 420px) {
  .sessions-bottom-section .data-container {
    width: 100%;
    height: auto;
    margin-top: 0%;
    margin-right: 0% !important;
  }
  .session-heading-text {
    width: 50%;
    font-size: 13px;
    overflow: hidden;
    margin: auto;
  }
  .left-top-container {
    width: 100%;
    justify-content: stretch;
    padding: 0%;
  }
}
