/* MIDDLE SECTION */
.managerekos-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* GLOBAL MIDDLE styles - Needed */
.managerekos-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.managerekos-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.managerekos-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

/* BOTTOM SECTION */
.managerekos-bottom-section {
  height: 23rem;
  position: relative;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: center;
}

.managerekos-bottom-section .data-container {
  height: auto;
  width: 70%;
  display: flex;
  justify-content: space-between;
  z-index: 100;
}

.managerekos-center-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.managerekos-heading-text {
  font-size: 18px;
  font-weight: bold;
  float: left;
  display: table-column;
  padding: 10px;
  background-color: #99d4cf;
  width: 40%;
}

.managerekos-action-heading {
  width: 20%;
}

.managerekos-column-text {
  font-size: 14px;
  color: #494b52;
  float: left;
  display: table-column;
  padding: 5px;
  width: 40%;
}

.managerekos-actions {
  width: fit-content;
}

.managerekos-action-text {
  font-size: 14px;
  color: #6ea29e !important;
  float: left;
  display: table-column;
  cursor: pointer;
  margin-right: 40px;
}

.managerekos-action-del {
  font-size: 14px;
  float: right;
  display: table-column;
  cursor: pointer;
  color: #6ea29e !important;
}

/* ADD REKO LINK */
.managerekos-homepage-link {
  color: #6ea29e;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
  margin-right: 10px;
}

.managerekos-homepage-link:hover,
.managerekos-action-text:hover,
.managerekos-action-del:hover {
  text-decoration: underline;
}

/* MEDIA SCREENS */
@media (max-width: 1200px) {
  .managerekos-bottom-section {
    margin-left: 5px !important;
  }
}

@media (max-width: 1100px) {
  .managerekos-bottom-section {
    margin-left: 5px !important;
  }
}

@media (max-width: 1024px) {
  .managerekos-bottom-section {
    margin-left: 5px !important;
  }
}

@media (max-width: 768px) {
  .managereko .bottom-section .data-container {
    width: 100%;
    margin-left: 5px !important;
  }
  .managereko .heading-text {
    width: 33%;
    font-size: 16px;
  }
  .managereko .column-text {
    width: 33%;
  }
}
