.info-icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1rem;
}

.old-info-icon-container {
  cursor: pointer;
}

svg.iconify.pen-icon {
  flex: auto;
  font-size: xx-large;
}

.pen-icon-container {
  font-size: x-large;
  background-color: #ffff;
  border-radius: 25px;
  width: 37px;
  height: 37px;
  box-shadow: 1px 1px 7px 0 #e4e4e4;
  padding: 3px 9px;
}

.textarea-container-group {
  display: flex;
  justify-content: space-between;
}
.top-margin {
  margin-top: -30px;
}

.top-margin:focus {
  box-shadow: 0px 0px 0px 0 #e4e4e4;
}
