.requestPassword {
  max-width: 992px;
  margin: 15px auto;
  overflow: hidden;
}

.requestPassword-content-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.requestPassword-content-container .circle-container {
  min-height: 150px;
  min-width: 150px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid transparent;
}

.requestPassword-content-container .logo-container {
  height: 145px;
  width: 150px;
  background-color: var(--background-color-mworks);
  border-radius: 50%;
  border: 10px solid rgba(153, 212, 207, 0.3);
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
  background-clip: padding-box;
  padding: 10% 0 3% 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly !important;
  font-size: 12px !important;
  font-weight: 600;
  color: var(--main-color-black);
}

.requestPassword-content-container .logo-container .logo {
  width: 65%;
  height: 60%;
}

.requestPassword-container-header-text {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}

.requestPassword-form-container {
  width: 100%;
}

@media screen and (max-width: 930px) {
  .requestPassword-container-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
}

@media screen and (max-width: 420px) {
  .requestPassword-container-header-text {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}
