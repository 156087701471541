.tellothers-header {
  width: 100%;
  margin-top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .tellothers-btn {
    margin-top: -50px;
  }
}

.tellothers-wrapper {
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 0 16px;

  .tellothers-row {
    overflow: auto;
    display: flex;
    flex-direction: column;
    height: 100% !important;
    flex-grow: 1;
    padding: 0px 5px 0px 5px;
    .heading {
      font-weight: bold;
      padding: 0 2px;
    }

    textarea {
      resize: none;
      height: 100%;
      overflow: auto;
      line-height: 1.5;
      background-color: #fff;
      font-size: 16px;
      color: #000000;
      border-radius: 0.2rem;
      padding: 1px;
      margin: 5px 0px 5px 0px;
    }
  }
}
