.ant-list-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 0;
}
.ant-list-item-meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1 1;
  -ms-flex-align: start;
  align-items: flex-start;
  font-size: 0;
}
.ant-list-item-meta-content {
  max-width: 650px;
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: 0.115em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon svg {
  display: inline-block;
}
svg:not(:root) {
  overflow: hidden;
}
.anticon > * {
  line-height: 1;
}

.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
}
.ant-list-item-meta-description {
  display: flex;
  color: black !important;
}
.mworks-chat-logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 17px;
  z-index: 999;
}
.chat-popup {
  width: 410px;
  height: 540px;
  border-radius: 10px;
  border: solid 1px #edf8f7;
  background-color: #ffffff;
  display: block;
  position: fixed;
  bottom: 66px;
  right: 15px;
  z-index: 999;
}
.chat-popup-header {
  width: 407px;
  height: 50px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(213, 213, 213, 0.5);
  background-color: #ffffff;
}
.chat-popup-header-image {
  width: 29px;
  height: 30px;
  object-fit: contain;
  background-color: #ffffff;
  margin: 10px 10px 10px 16px;
}
.chat-popup-header-text {
  width: 43px;
  height: 24px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #292929;
}
.chat-popup-header-close-button {
  width: 126px;
  height: 24px;
  font-family: Nunito;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #16433f;
  float: right;
  margin: 13px 7px 17px 7px;
  border: none;
  background-color: #ffffff;
}
.chat-popup-client-text {
  width: 300px;
  opacity: 0.75;
  border-radius: 16px;
  background-color: #e5e5e5;
  padding: 10px;
  margin-bottom: 10px;
  font-family: IsidoraSans;
  color: #000000;
}

@-webkit-keyframes growingChatMessage {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-moz-keyframes growingChatMessage {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
@-o-keyframes growingChatMessage {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes growingChatMessage {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}

#last-message {
  animation: growingChatMessage 0.25s ease-in;
  -webkit-animation: growingChatMessage 0.25s ease-in;
  -moz-transform: growingChatMessage 0.25s ease-in;
  -ms-transform: growingChatMessage 0.25s ease-in;
  -o-transform: growingChatMessage 0.25s ease-in;
}

@media only screen and (max-width: 1024px) {
  .chat-popup-bot-text {
    width: 285px;
    opacity: 0.75;
    border-radius: 16px;
    background-color: #edf8f7;
    margin: 0px 0px 0px 40px;
    padding: 10px;
    font-family: IsidoraSans;
    color: #16433f;
  }
}
@media only screen and (min-width: 1024px) {
  .chat-popup-bot-text {
    width: 300px;
    opacity: 0.75;
    border-radius: 16px;
    background-color: #edf8f7;
    margin: 0px 0px 0px 78px;
    padding: 10px;
    font-family: IsidoraSans;
    color: #16433f;
  }
}
.chat-popup-button {
  border-radius: 5px;
  background-color: #99d4cf;
  border: none;
  color: #16433f;
  float: right;
  margin-left: 7px;
  padding: 2px 10px;
}
.chat-popup-message-area {
  height: 425px;
  width: 100%;
  overflow: auto;
  background-color: #ffffff;
  padding-bottom: 0px;
  margin-bottom: 5px;
}
.chat-popup input {
  width: 340px;
  height: 47px;
  border-radius: 10px;
  background-color: #f8f8f8;
  border: none;
  margin-left: 15px;
  font-family: Nunito;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #898989;
}
.chat-popup-send-button {
  width: 24px;
  height: 24px;
  object-fit: contain;
  background-color: #99d4cf;
  background: url('../../assets/send.svg');
  background-repeat: no-repeat;
  border: none;
  margin-left: 10px;
  vertical-align: middle;
}
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}

.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

.form-container .cancel {
  background-color: red;
}

.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .chat-popup-bot-text {
    width: 285px;
    opacity: 0.75;
    border-radius: 16px;
    background-color: #edf8f7;
    margin: 0px 0px 0px 40px;
    padding: 10px;
    font-family: IsidoraSans;
    color: #16433f;
  }
}
@media only screen and (min-width: 1024px) {
  .chat-popup-bot-text {
    width: 300px;
    opacity: 0.75;
    border-radius: 16px;
    background-color: #edf8f7;
    margin: 0px 0px 0px 78px;
    padding: 10px;
    font-family: IsidoraSans;
    color: #16433f;
  }
}

/*Mobile view*/
@media (max-width: 992px) {
  .chat-body {
    height: 95%;
  }

  .chat-popup-message-area {
    margin-bottom: 15px !important;
  }

  .mworks-chat-logo {
    bottom: 58px !important;
  }

  .chat-popup-header {
    width: 100%;
  }

  .mworks-chat-logo {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 60px;
    right: 7px;
    z-index: 110;
  }

  .chat-popup {
    width: 90%;
    height: 500px;
    border-radius: 10px;
    border: solid 1px #edf8f7;
    background-color: #ffffff;
    display: block;
    position: fixed;
    bottom: 70px !important;
    right: 5px;
    z-index: 150;
    box-shadow: 0 0 15px 0 #ccc;
  }

  .chat-popup-message-area {
    height: 75%;
  }

  .chat-popup input {
    width: 83%;
  }

  .chat-popup-client-text {
    margin-bottom: 10px !important;
  }
}

/**Typing dots***/
.typing-dot-container {
  margin-left: 20px;
}
.typing-dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  margin-left: 4px;
  display: inline-block;
  background-color: #4caf50;
  animation: 1.2s typing-dot ease-in-out infinite;
}
.typing-dot:nth-of-type(2) {
  animation-delay: 0.15s;
}
.typing-dot:nth-of-type(3) {
  animation-delay: 0.25s;
}
@keyframes typing-dot {
  15% {
    transform: translateY(-35%);
    opacity: 0.5;
  }
  30% {
    transform: translateY(0%);
    opacity: 1;
  }
}
