.word-cloud {
  display: inline-grid;
  grid-gap: 1px 1px;
  grid-template-columns: 30% 30% 30%;
  grid-template-rows: auto auto;
  justify-content: space-around;
  display: contents;
  background-color: white;
  border-radius: 20px;
  box-shadow: 2px 6px 16px 0 rgba(155, 155, 155, 0.15);
  border: none;
}
.word-cloud-container {
  display: inline-block;
  justify-content: center;
  background-color: white;
  height: 100%;
  margin: 5px 20px 0px 20px;
}
.strengthName {
  width: 250px;
  height: 42px;
  border-radius: 4px;
  border: solid 1px #e0e0e0;
  font-size: 16px;
  font-weight: 200;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #494b52;
  margin: 27px 17px 1px 0px;
  padding-left: 15px;
}
.word-cloud .color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 11px 0 11px;
}
.word-cloud .color-container {
  display: flex;
  padding: 40px 0px 40px 0;
}
.word-cloud .col-lg-12.col-sm-12.color-picker-container {
  padding: 0px !important;
}
.word-cloud .slide-label {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  padding: 15px;
}
.word-cloud .modal-footer {
  border-top: 0px;
}
.word-cloud .slide {
  margin-bottom: 34px;
}
.rc-slider-track,
.rc-slider-handle {
  background-color: #1976d3;
}
.rc-slider-dot-active {
  border-color: #1976d3;
}
.rc-slider-handle {
  border: solid 2px #1976d3;
}
.rc-slider {
  margin-left: 20px;
}
.rc-slider-mark-text {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  color: #1f5285;
}
.rc-slider-rail {
  background-color: #aeccef;
}
@media (max-width: 700px) {
  .rc-slider {
    width: 88%;
  }
  .cloud-modal .save {
    top: 355px;
  }
}
